/* @media only screen and (max-width: 800px) { */

.mobile-me-style {
    .mobiTopSpacer {
        background-color: var(--mainTurqi);
        height: 90px;
    }

    .inMastrelative {
        position: relative;
    }

    .detailsDiv {
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        // top: -25px;
    }

    .HPTopImagesWraper {
        box-sizing: border-box;
        border-top: 25px solid white;
        border-right: 25px solid white;
        border-left: 25px solid white;
        border-radius: 30px;
        top: -25px;
        position: relative;
    }

    .fatLIne {
        margin-top: 0px;
    }

    .HPTopImages .swiper-container {
        padding-bottom: 35px;
    }

    .HPTopImage {
        width: 100%;
        object-fit: cover;
        background-color: white;
        border-radius: 15px;

    }

    .specialPopsImages {
        width: 100%;
        margin-bottom: 15px;
    }

    .spopImgWrap {
        padding-top: 10px;
        width: 100%;
        text-align: center;
    }

    .spopImage {
        max-width: 50%;
        margin-bottom: 5px;
    }

    .spopimgTxt {
        text-align: center;
        position: absolute;
        top: 50%;
        width: 80%;
        transform: translate(-15%, -50%);
    }


    .Fotter .HPTopImages {
        top: 0;
        border: none;
    }

    .Fotter .HPTopImages .swiper-container {
        padding-bottom: none;
    }

    .Hpage {
        margin-bottom: -35px;
        position: relative;
    }

    .Hpage .detailsDiv {
        border-top-right-radius: none;
        border-top-left-radius: none;
        top: -35px;
    }

    .ORDTYLine .theImg {
        width: 25px;
        height: 30px;
        margin-left: 5px;
    }

    .OrderTypeSelectPop .MuiPaper-root,
    .approveMsgPopup .MuiPaper-root {
        width: 90%;
        max-width: 80%;
        margin: 0;
        min-height: unset;
    }

    .OrderTblPop .MuiPaper-root,
    .approveMsgPopup .MuiPaper-root {
        width: 90%;
        max-width: 80%;
        margin: 0;
        min-height: unset;
    }

    .landingPagepopUp .MuiPaper-root,
    .approveMsgPopup .MuiPaper-root {
        width: 98%;
        max-width: 80%;
        margin: 0;
        min-height: unset;
    }


    .landingPagepopUp .MuiBackdrop-root {
        background-color: white;
    }

    .imgblok {
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 65%;
        width: 100%;
        margin: 0 auto;
    }

    .OrderTypeSelectPop .popUpCloseBtn {
        top: 30%;
        // left: 15%; */
        right: 10px;
        position: absolute;
    }

    .OrderTblPop .popUpCloseBtn {
        top: 2%;
        right: 5%;
        /* right: unset; */
        position: absolute;
    }

    .landingPagepopUp .popUpCloseBtn {
        top: 2%;
        right: 5%;
        /* right: unset; */
        position: absolute;
    }

    .iframeBlock .MuiPaper-root,
    .iframeBlock .css-uhb5lp {
        width: 95%;
        height: 100%;
        max-width: unset;
        margin: 2px;
    }

    .iframeBlockIframe {
        width: 100%;
        height: 100%;
        margin: 0px;
        border: none;
    }

    .iframeBlockDialogContent .iframeCloseBtn {
        top: 80px;
    }

    .iframeBlockDialogContent {
        padding: 0px !important;
    }

    .css-1ty026z {
        flex: 1 1 auto;
        overflow-y: auto;
        padding: 5px 10px;
    }

    .iframeCloseBtn {
        width: 20px;
        height: 20px;
        position: absolute;
    }

    .iframeCloseBtn svg {
        width: 140%;
        height: 140%;
        transform: rotate(0deg);
        color: var(--turqiText);
        z-index: 500;
        /* left: 5px;
    top: -10px; */
        position: relative;
    }

    .headOnFix {
        text-align: center;
        background-color: white;
        padding-top: 0px;
        padding-bottom: 3px;
    }

    .onPhoneP {
        font-weight: bold;
        font-size: 1.3rem;
        float: unset;
        padding-top: 5px;
        margin: 0 auto;
    }

    .headOnPFix {
        text-align: center;
        background-color: white;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .onPhonePFix {
        font-weight: bold;
        font-size: 1rem;
        float: unset;
        padding-top: 0px;
        margin: 0 auto;
        text-align: center;
    }

    .restHeader {
        font-size: var(--anakMeod);
        color: black;
        margin: 0 auto;
        display: block;
        float: unset;
    }

    .mobiLogoDiv {
        // width: 50px;
        height: 40px;
        // top: -5px;
        position: relative;
    }

    .mobiLogoDiv img {
        width: 100%;
        max-height: 40px;
        position: relative;
    }

    .roundedTops {
        padding-top: 20px;
        border-start-end-radius: 20px;
        border-start-start-radius: 20px;
        top: -20px;
        position: relative;
        z-index: 90;
        background-color: white;
    }

    .mydtPicker .rdp {
        --rdp-cell-size: 40px;
    }

    .OrderTableBtn {
        color: white;
        text-align: center;
        border-radius: 5px;
        background-color: var(--mainColor);
        padding-top: 10px;
        padding-bottom: 10px;
        width: 90%;
        box-shadow: -2px 2px 7px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
        transition-property: box-shadow;
        transition-duration: 0.5s;
        margin: 5px auto;
    }

    .OrderTableBtn:hover {
        color: white;
        box-shadow: none;
    }

    .closePopWinBtn {
        overflow: hidden;
        display: block;
        position: relative;
        height: 40px;
    }

    .textSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        width: 100%;
        margin: 5px 0;
    }

    .searchDropdown {
        width: 94%;
    }

    .BottomMast {
        position: relative;
    }

    .BottomMast .HPTImgWrap {
        width: 100%;
    }

    .BottomMastBtn {
        border-radius: 10px;
        direction: rtl;
        width: 90% !important;
        left: 5%;
    }

    .singlPImg {
        width: 90%;

    }

    .offOrdItm,
    .onOrdItm {
        border: 1px solid lightgray;
        border-radius: 15px;
        padding: 15px 7px;
        margin-bottom: 10px;


    }

    .offOrdItm .smallDIspImageWrap,
    .onOrdItm .smallDIspImageWrap {
        padding: unset;
        text-align: center;
    }

    .offOrdItm .smallDIspImage,
    .onOrdItm .smallDIspImage {
        max-width: 130px;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 8px;
        margin: 0 auto;
    }

    .offOrdItm .orderLINE svg,
    .onOrdItm .orderLINE svg {
        color: var(--btnBlue);
    }

    .myBtnWithIcon {
        width: 125px !important;
        margin-bottom: 10px !important;
        border-color: var(--btnBlue) !important;
        color: var(--btnBlue) !important;
        box-shadow: 2px 2px 3px lightgray;
    }


    .myBtnWithIcon:hover {
        background-color: var(--btnBlue) !important;
        color: white !important;
        box-shadow: 2px 2px 3px lightgray;
    }

    .myBtnWithIcon .MuiButton-startIcon {
        margin-left: 3px;
        margin-right: -4px;
    }

    .myBtnWithIconSmall {
        width: 70px !important;
        margin-bottom: 10px !important;
    }

    .myBtnWithIconSmall .MuiButton-startIcon {
        margin-left: 3px;
        margin-right: -4px;
    }

    .myBtnWithIconSmall:hover {
        background-color: var(--btnBlue) !important;
        color: white !important;
    }

    .insideFrame {
        padding: 5px !important;
    }

    .btnWithIconTxt {
        line-height: 1.2rem;
        font-size: var(--mediumFont);
    }

    .MyOrdersWrap {
        width: 100%;
        margin: 0 auto;
    }

    .ordDetailsDiv {
        border: 1px solid lightgray;
        border-radius: 15px;
        padding: 15px 7px;
    }


    .headOnFixC {
        background-color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        position: fixed;
        min-height: 27px;
        z-index: 850;
        border-bottom: 1px solid var(--btnBlue);
        box-shadow: 0px -3px 8px 0px #000000;
    }

    .offOrdItm .ordDetailsDiv {
        /* border: none !important;
    background-color: white; */

    }

    .offOrdItm .headsline svg {
        margin: 0px 5px 0px 15px;
        top: 4px;
        position: relative;
    }

    .offOrdItm .headsline span {
        margin: 0px 5px 0px 15px;
    }

    .TheOrderShow {
        width: 90%;
        margin: 0 auto;
    }

    .shownMe {
        background-color: var(--lGray);
    }


    .MessagesObj {
        position: fixed;
        color: white;
        background-color: #1f1f1f;
        padding: 20px 25px;
        bottom: -100%;
        // min-width: 300px;
        width: calc(98% - 50px);
        right: 0.5%;
        min-height: 150px;
        border: 2px solid white;
        transition: bottom 1.5s ease;
        border-radius: 15px 15px 0px 0px;
        font: var(--MyFont);
    }

    .closeItMsg {
        position: absolute;
        border-radius: 50%;
        line-height: 30px;
        color: white;
        background-color: #1f1f1f;
        width: 30px;
        height: 30px;
        text-align: center;
        top: -15px;
        right: calc(50% - 15px);
        border: 2px solid white;
        cursor: pointer;

    }

    .showMsgObj {
        bottom: 0;
    }

    .MessagesObjTxt {
        padding-top: 10px;
        text-align: center !important;
    }

    // .reservationBigBtn.smalPad {
    //     font-size: 0.9rem;
    //     padding-top: 12px;
    //     padding-bottom: 13px;
    // }
    @media only screen and (max-width: 300px) {
        .reservationBigBtn {
            border: 1px solid;
            border-color: rgba(0, 0, 0, 0.6);
            width: calc(100% - 10px);
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 0.75rem;
            margin: 5px;
            border-radius: 5px;
            text-align: center;
            color: black;
            box-shadow: 0 3px 6px -14px rgba(0, 0, 0, .2), 5px 5px 10px 0 rgba(0, 0, 0, .19);
            transition-property: box-shadow;
            transition-duration: 0.5s;
        }

        .reservationhourSelItm {
            font-size: 0.75rem;
        }
    }
}


/* } */