:root {
    --lGray: #d7dce4;
    --GrayPlus: #989ca1;
    --mainColor: #21ccd4;
    /* --btnBlue: #1976d2; */
    --btnBlue: #21ccd4;
    --verySmall: 75%;
    --veryVerySmall: 70%;
    --smallFont: 70%;
    --mediumFont: 80%;
    --regularFont: 100%;
    --MbigFont: 110%;
    --bigFont: 120%;
    --veryBigFont: 140%;
    --huge: 160%;
    --anak: 200%;
    --anakMeod: 240%;
    --anakMeodMeod: 300%;
    --swiper-theme-color: #fec000 !important;
}

.max600
{
    max-width: 600px;
    margin: 0 auto;
}
.normalLine
{
    line-height: 1.1 !important;
}
.onlyOnDeskTop {
    display: none;
}

#ccIframe {
    min-height: 650px !important;
}

.onlyOnMobile {
    display: block;
}

body {
    margin: unset !important;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.noPadding {
    padding: unset !important;
}

.noMargin {
    margin: unset !important;
}

.fullRow {
    display: block;
    width: 100%;
}

.overflowHid {
    overflow: hidden;
}

.textLtr,
.textLtr .SinglePageContent {
    direction: ltr !important;
    text-align: left !important;
}

.english {
    font-family: "Arial";
    font-size: 100%;
    direction: ltr;
}

.centerText {
    text-align: center;
}

.centerMargin {
    margin: 0 auto;
}

.leftText {
    text-align: left;
}

.bigLineHeight {
    line-height: 1.2rem;
    margin-bottom: 15px;
}

.bold {
    font-weight: bold;
}

.verySmall {
    font-size: var(--verySmall);
}

.veryVerySmall {
    font-size: var(--veryVerySmall);
}

.vSmall {
    font-size: var(--smallFont);
}

.mediumst {
    font-size: var(--mediumFont);
}

.regular {
    font-size: var(--regularFont);
}

.MbigFont {
    font-size: var(--MbigFont);
}

.big {
    font-size: var(--bigFont);
}

.veryBig {
    font-size: var(--veryBigFont);
}

.huge {
    font-size: var(--huge);
}

.anak {
    font-size: var(--anak);
}

.anakMeod {
    font-size: var(--anakMeod);
}

.margTop {
    margin-top: 5px;
}

.margTopBig {
    margin-top: 10px;
}

.spacePad {
    padding-top: 5px;
    padding-bottom: 5px;
}

.spacePadSmall {
    padding-top: 2px;
    padding-bottom: 2px;
}

.spacePadBig {
    padding-top: 10px;
    padding-bottom: 10px;
}

.spaceTop {
    padding-top: 5px;
}

.spaceTopBig {
    padding-top: 10px;
}

.spaceTopVeryBig {
    padding-top: 20px;
}

.spaceButtom {
    padding-bottom: 5px;
}

.spaceButtomBig {
    padding-bottom: 10px;
}

.grayText {
    color: var(--GrayPlus);
}

.spaceRight {
    padding-right: 5px;
}

.spaceLeft {
    padding-left: 5px;
}

.spaceLeftLong {
    padding-left: 15px;
}

.spaceRightLongi,
.spaceRightLong {
    padding-right: 15px;
}

.spaceRightVeryLong {
    padding-right: 35px;
}

.spaceMargRightVeryLong {
    margin-right: 35px;
}

.spaceMargTopBig {
    margin-top: 10px;
}
.spaceMargTopVBig {
    margin-top: 50px;
}

.spaceMargBottomSmall {
    margin-bottom: 5px;
}

.spaceMargBottomBig {
    margin-bottom: 10px;
}

.spaceMargBottomVeryBig {
    margin-bottom: 20px;
}
.wrapPadding {
    /* padding: 2px; */
    padding-bottom: 15px;
}

.onRight {
    float: right;
}

.onLeft {
    float: left;
}

.centerMe {
    margin: 0 auto;
}

.noBorder {
    border: none !important;
}

p {
    margin-block-start: 0.5px;
    margin-block-end: 0.5px;
}

a {
    text-decoration: none;
    color: black;
}

a:visited {
    color: black;
}

textarea {
    font-family: var(--MyFont);
}

.SinglePageContent a,
.SinglePageContent p,
.SinglePageContent span,
.SinglePageContent h1,
.SinglePageContent h2,
.SinglePageContent ul,
.SinglePageContent li {
    font-family: var(--myFont) !important;
}

.flexMe {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.arrowing
{
    cursor: default;
}
.pointing:hover {
    cursor: pointer;
}

.setTheTop {
    margin-top: -15px;
}

.tdmin10 {
    width: 15%;
    vertical-align: top;
}

.min100 {
    min-width: 100px;
}

.showMe {
    display: block;
}

.hideMe {
    display: none;
}

.InRed {
    color: red;
}

.fullWidth {
    width: 100%;
    overflow: hidden;
}

.overFlHid {
    overflow: hidden;
}

.halfFromLeft {
    width: 50%;
    float: left;
}

.halfFromRight {
    width: 50%;
    float: Right;
}

.headORdTxt::before {
    content: " | ";
}

span.Heads {
    width: 100%;
    display: block;
    line-height: 25px;
    font-weight: bold;
}

.SinglePage {
    min-height: 200px;
    margin: unset;
}

.ContactPage {
    min-height: 200px;
    margin: unset;
    margin-bottom: 60px;
}

.imInOrdBtn {
    font-weight: normal;
    display: table;
    float: left;
    border: 1px solid var(--lGray);
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
    font-size: 70%;
}

.ContCall {
    display: table;
    margin: 0 auto;
    text-align: center;
    margin-top: 15px;
    font-weight: bold;
}

.ContactsData {
    margin: 0 auto;
    width: 70%;
    padding-top: 50px;
}

.contWrap {
    margin-bottom: 10px;
}

.ContactsData label {
    margin-top: 10px;
}

.contactGo {
    max-width: 100px;
    background-color: black;
    color: white;
    border: none;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 20px;
    margin-top: 10px;
}

.ContactsData input,
.ContactsData textarea {
    border: none;
    border-bottom: 1px solid black;
}

.singlePagePad {
    padding-top: 55px;
}

.SinglePageContent {
    padding-top: 25px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    text-align: right;
}

.ContactPageContent {
    padding-top: 25px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

span.headORdTxt {
    line-height: 16px;
}

.headORdLine {
    line-height: 100%;
    padding-top: 5px;
    font-size: 14px;
    max-width: 70%;
}

.blackRadio {
    color: black !important;
    padding: 6px;
}

.blackRadiolbl span,
.blackRadiolbl input {
    font-size: 15px;
}

.spacerMastBtn,
.spacerfavBtn {
    opacity: 0;
}

.MyOrdersLst.historys {
    /* max-height: 400px; */
    overflow-y: auto;
}
/* 
.inputPhonetx {
    border: none;
    border: 1px solid var(--mainColor) !important;
    border-radius: 10px;
    background-color: var(--lGray);
} */

.inputPhonetx {
    padding: 15px 0px;
    width: 98%;
    margin: 0 auto !important;
    text-align: center;
    font-size: var(--mediumFontSize);
    border-radius: 15px;
    background-color: var(--lGray);
    color:white;
    border-color: unset;
    border:unset;
    color: black;
    -moz-appearance: textfield;
}
.inputPhonetx:focus
{
    outline: none;
}
.inputPhonetx::-webkit-outer-spin-button,
.inputPhonetx::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 


.inputPhonetx .MuiInput-root,
.inputPhonetx .MuiInput-root input {
    max-width: unset !important;
    border-bottom: none;
    text-align: center;
}

.inputPhonetx .MuiInput-root input:before,
.inputPhonetx .MuiInput-root input:after {
    border-bottom: none;
}

.inputPhonetx .MuiInput-root:before,
.inputPhonetx .MuiInput-root:after {
    border-bottom: none;
} */

.stepper {
    display: none;
    /* position: fixed;
    background-color: white;
    z-index: 5;
    right: 5px;
    top: 90px; */
}

.gamaFrame {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.divDescriptition {
    padding-right: 15px;
}

.stepper .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    width: 0.7em !important;
    height: 0.6em !important;
}

.stepper .css-1pe7n21-MuiStepConnector-root {
    margin-left: 35% !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 4px !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: unset !important;
}


/* Container styles */

.syntax-highlighter-container {
    font-family: monospace;
    /* Use a monospace font for consistent spacing */
    font-size: 14px;
    /* Adjust font size as needed */
    line-height: 1.5;
    /* Adjust line height for readability */
    padding: 10px;
    /* Add padding for spacing */
    border: 1px solid #ccc;
    /* Add a border for separation */
    border-radius: 4px;
    /* Add rounded corners */
    background-color: #f8f8f8;
    /* Background color for code block */
    overflow-x: auto;
    /* Enable horizontal scrolling for long lines */
}


/* XML element styles */

.syntax-highlighter-xml .tag {
    color: #000080;
    /* Color for XML tags */
}


/* XML attribute styles */

.syntax-highlighter-xml .attr {
    color: #008080;
    /* Color for XML attributes */
}


/* XML text content styles */

.syntax-highlighter-xml .value {
    color: #008000;
    /* Color for XML text content */
}

.buisReservationPopBtns {}

.reservationDispPanel {
    /* min-height: 455px; */
    position: relative;
    overflow: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20%;
}

.reservationBtWrap {
    width: 25%;
}

.reservationBigBtn {
    border: 1px solid;
    border-color: rgb(0 0 0 / 6%);
    width: calc(100% - 10px);
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.2rem;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    color: black;
    box-shadow: 0 3px 6px -14px rgba(0, 0, 0, .2), 5px 5px 10px 0 rgba(0, 0, 0, .19);
    transition-property: box-shadow;
    transition-duration: 0.5s;
}

.reservationBigBtn:hover {
    color: white;
    background-color: var(--mainColor);
    box-shadow: none;
}

.selectedINColor {
    color: white;
    background-color: var(--mainColor);
    box-shadow: none;
}

.reservationBigBtn.smalPad {
    font-size: 0.9rem;
    padding-top: 12px;
    padding-bottom: 13px;
}

.mydtPicker {
    display: table;
    text-align: center;
    margin: 0 auto;
}

.mydtPicker .rdp-day_selected,
.mydtPicker .rdp-day_selected:focus-visible,
.mydtPicker .rdp-day_selected:hover {
    background-color: var(--mainColor);
}

.reservationhourSelItm {
    float: right;
    width: calc(25% - 25px);
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    text-align: center;
}

.reservationSpecSelItm {
    float: right;
    /* width: calc(25% - 25px); */
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    text-align: center;
}

.selectedHou {
    background-color: var(--mainColor);
    color: white;
}

.resrvationOrdBtnWrap {
    position: absolute;
    bottom: 15px;
    width: 80%;
    left: 10%;
}

.resrvationOrdMeBtn {
    border: 1px solid;
    border-color: rgb(0 0 0 / 6%);
    color: white;
    text-align: center;
    border-radius: 5px;
    background-color: var(--mainColor);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    box-shadow: 0 3px 6px -14px rgba(0, 0, 0, .2), 5px 5px 10px 0 rgba(0, 0, 0, .19);
    transition-property: box-shadow;
    transition-duration: 0.5s;
}

.resrvationOrdMeBtn_in {
    width: calc(100% - 20px);
    margin: 0 auto;
}

.resrvationOrdMeBtn:hover {
    color: white;
    box-shadow: none;
}

.onTopOfAll {
    z-index: 99999 !important;
}

.onTopOfSome {
    z-index: 555 !important;
}

.rowTb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 5px;
    margin-bottom: 5px;
    /* border-bottom: 1px solid lightgray; */
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    /* padding-right: 15px;
    padding-left: 15px */
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}


.searchDropdown {
    position: absolute;
}

.dropdown-content {
    position: relative;
    top: 100%;
    right: 0;
    z-index: 9999;
    background-color: white;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px dotted lightblue;
}

.thumbImg {
    width: 30%;
    height: auto;
    margin-left: 10px;
    border-radius: 5px;
}

.dropdown-item span {
    flex: 1;
}

.waitingSpinner {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.waitingSpinner .spinner-8 {
    margin: 0 auto !important;
    width: 30px !important;
    height: 30px !important;
}

.MuiDialog-container {
    align-items: self-start !important;
}

.specialContactPop .MuiDialog-container .MuiPaper-root {
    width: 100%;
    margin: 10px;
}



.centerPop .MuiPaper-root {
    top: 40% !important;
    transform: translate(0, -50%) !important;
}

.mediumSizePop .MuiPaper-root {
    max-width: 550px;
}

.ccTknPopCard {
    width: 100% !important;

}

.ccTknPopCard .MuiPaper-root {
    top: 40% !important;
    transform: translate(0, -50%) !important;
    border-radius: 30px !important;
    padding: 20px !important
        /* min-width: 50%;
     min-height: 50%; */
}


.theTitlePop_1 {
    position: relative;
    /* margin-top: 15px !important; */
    top: -18px;
    z-index: -1 !important;
    border-bottom: none !important;
    background-color: transparent !important;

}

.theTitlePop_1,
.theTitlePop_1 span {
    font-family: var(--SecondFont) !important;
    line-height: 1.3 !important;
}


.popUpCloseBtn_Small {

    top: 0px !important;
    right: 0px !important;
    position: relative !important;
    font-size: 25px !important;
    z-index: 5000;
}

.ccTknPopCard .MuiDialogContent-root,
.ccTknPopCard .MuiDialogActions-root 
{
    padding: unset !important;
}

.ccTknPopCard .selBlk {
    background-color: #d3d3d354 !important;
    margin-bottom: 20px !important;
    border-radius: 10px !important;
}

.ccTknPopCard .btn {
    padding: 10px !important;
}


.TokenChargeBtn.btnPopRegularSec {
    background: none !important;
    font-size: var(--MyFont) !important;
    text-decoration: underline !important;
    line-height: 2rem !important;
}


.MessagesObj a,
.MessagesObj span,
.MessagesObj p,
.MessagesObj div,
.MessagesObj div a,
.MessagesObj div span,
.MessagesObj div p
{
color: white !important;
}
.MessagesObj a,
.MessagesObj div a
{
    text-decoration: underline !important;
}