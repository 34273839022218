@font-face {
    font-family: 'FbPractica_black';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbPractica-Black.eot);
    src: url(DATA/Fonts/FbPractica-Black.otf);
    src: url(DATA/Fonts/FbPractica-Black.otf) format('embedded-opentype'), url(DATA/Fonts/FbPractica-Black.otf) format('otf'), url(DATA/Fonts/FbPractica-Black.otf) format('truetype');
}

@font-face {
    font-family: 'FbPractica_Bold';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbPractica-Bold.eot);
    src: url(DATA/Fonts/FbPractica-Bold.otf);
    src: url(DATA/Fonts/FbPractica-Bold.otf) format('embedded-opentype'), url(DATA/Fonts/FbPractica-Bold.otf) format('otf'), url(DATA/Fonts/FbPractica-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'FbPractica_Light';
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbPractica-Light.eot);
    src: url(DATA/Fonts/FbPractica-Light.otf);
    src: url(DATA/Fonts/FbPractica-Light.otf) format('embedded-opentype'), url(DATA/Fonts/FbPractica-Light.otf) format('otf'), url(DATA/Fonts/FbPractica-Light.otf) format('truetype');
}

@font-face {
    font-family: 'FbPractica_Regular';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbPractica-Regular.eot);
    src: url(DATA/Fonts/FbPractica-Regular.otf);
    src: url(DATA/Fonts/FbPractica-Regular.otf) format('embedded-opentype'),
     url(DATA/Fonts/FbPractica-Regular.otf) format('otf'), 
     url(DATA/Fonts/FbPractica-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'FbEmeq-Regular';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbEmeq-Regular.otf);
    src: url(DATA/Fonts/FbEmeq-Regular.otf) format('embedded-opentype'), url(DATA/Fonts/FbEmeq-Regular.otf) format('otf'), url(DATA/Fonts/FbEmeq-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'FbEmeq-Bold';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbEmeq-Bold.otf);
    src: url(DATA/Fonts/FbEmeq-Bold.otf) format('embedded-opentype'), url(DATA/Fonts/FbEmeq-Bold.otf) format('otf'), url(DATA/Fonts/FbEmeq-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'OpenSans-Bold';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(DATA/Fonts/FbPractica-Regular.eot);
    src: url(DATA/Fonts/FbPractica-Regular.otf);
    src: url(DATA/Fonts/FbPractica-Regular.otf) format('embedded-opentype'),
     url(DATA/Fonts/FbPractica-Regular.otf) format('otf'), 
     url(DATA/Fonts/FbPractica-Regular.otf) format('truetype');
    /* src: url(DATA/Fonts/OpenSans-Bold.ttf);
    src: url(DATA/Fonts/OpenSans-Bold.ttf);
    src: url(DATA/Fonts/OpenSans-Bold.ttf) format('embedded-opentype'), url(DATA/Fonts/OpenSans-Bold.ttf) format('otf'), url(DATA/Fonts/OpenSans-Bold.ttf) format('truetype'); */
    unicode-range: U+0025-00FF;
}


@font-face {
    font-family: 'FbMetali-Regular';
    /* font-weight: 400; */
    font-style: normal;
    font-display: swap;
    /* src: url(DATA/Fonts/FbMetali-Regular.eot); */
    src: url(DATA/Fonts/FbMetali-Regular.otf);
    src: url(DATA/Fonts/FbMetali-Regular.otf) format('embedded-opentype'), url(DATA/Fonts/FbMetali-Regular.otf) format('otf'), url(DATA/Fonts/FbMetali-Regular.otf) format('truetype');
}

@font-face {
    font-family: 'FbMetali-Bold';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    /* src: url(DATA/Fonts/FbMetali-Bold.eot); */
    src: url(DATA/Fonts/FbMetali-Bold.otf);
    src: url(DATA/Fonts/FbMetali-Bold.otf) format('embedded-opentype'), url(DATA/Fonts/FbMetali-Bold.otf) format('otf'), url(DATA/Fonts/FbMetali-Bold.otf) format('truetype');
}

@font-face {
    font-family: 'FbMetali-Light';
    font-weight: lighter;
    font-style: normal;
    font-display: swap;
    /* src: url(DATA/Fonts/FbMetali-Light.eot); */
    src: url(DATA/Fonts/FbMetali-Light.otf);
    src: url(DATA/Fonts/FbMetali-Light.otf) format('embedded-opentype'), url(DATA/Fonts/FbMetali-Light.otf) format('otf'), url(DATA/Fonts/FbMetali-Light.otf) format('truetype');
}

@font-face {
    font-family: 'FbMetali-Medium';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    /* src: url(DATA/Fonts/FbMetali-Medium.eot); */
    src: url(DATA/Fonts/FbMetali-Medium.otf);
    src: url(DATA/Fonts/FbMetali-Medium.otf) format('embedded-opentype'),
     url(DATA/Fonts/FbMetali-Medium.otf) format('otf'), 
     url(DATA/Fonts/FbMetali-Medium.otf) format('truetype');
}