/* @media only screen and (min-width: 800px) { */

.desktop-me-style {
    &:root {
        --theMax: 1300px;
    }
    .onlyOnDeskTop {
        display: block;
    }
    .onlyOnMobile {
        display: none;
    }
    .firstStrMaster {
        // height: auto;
        // padding: 10px;
    }
    .mastSec {
        direction: rtl;
        width: 90%;
        margin: 0 auto;
        max-width: 1300px;
    }
    element.style {
        background-image: url(http://10.0.0.50:8000/images/Uploads/MASTER/6.png);
        background-position: center center;
        background-size: 85%;
        background-repeat: no-repeat;
    }
    .outOfStock:after {
        top: 35%;
        left: 10%;
        font-size: inherit;
    }
    .stickeySideMsg {
        width: 150px;
        height: 150px;
        left: 5%;
    }
    .HighlightOffSticky {
        font-size: 22px !important;
        top: 10px;
        left: 8px;
        position: relative;
    }
    .BottomMastBtn {
        border-radius: 10px;
        direction: rtl;
        width: 33% !important;
        left: 0%;
    }
    .BottomBannerImages .swiper-wrapper {
        left: 0%;
    }
    .RestGoogleMap {
        margin-top: 70px;
    }
    .headeronDeskTop {
        overflow: hidden;
        width: 100%;
        margin-bottom: 50px;
    }
    .headeronDeskTop .onRight {
        float: right;
        width: 60%;
    }
    .SearchDiv {
        margin-top: unset;
    }
    .inMast {
        position: fixed;
        width: 100%;
        z-index: 900;
        top: 0;
        background-color: var(--newMenuTurq);
    }
    .pickOrDel {
        font-size: var(--huge);
    }
    .rSideFirst {
        width: 30px;
        float: right;
        min-height: 1px;
    }
    .Hpage {
        max-width: 1300px;
        margin: 0 auto;
        margin-top: 80px;
    }
    .mobiMnuDiv {
        overflow: hidden;
        text-align: left;
        z-index: 1000;
        position: unset;
        float: left;
    }
    .srchDskTop {
        min-width: 280px;
        padding-top: 15px;
    }
    .logOutBtn {
        width: 40px;
    }
    .mobiLogoDiv img {
        max-height: 40px;
        width: auto;
        max-width: 200px;
    }
    .detailsDiv {
        top: unset;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
    .FourMainLnksDiv {
        width: 100%;
    }
    .swiper-container:before {
        content: '';
        font-size: 90px;
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0);
        opacity: 0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        z-index: 95;
    }
    .swiper-container:before {
        opacity: 0;
    }
    .swiper-container:hover:before {
        opacity: 1;
    }
    .inOutLogDiv {
        left: 40px;
        position: relative;
        padding: unset;
        float: left;
    }
    .pgSmallTxt {
        /* min-height: 35px; */
        white-space: nowrap;
    }
    .logOnDsk {
        width: 45px;
    }
    .inOutLogDiv svg {
        padding-left: unset;
        padding-right: 5px;
        width: 25px;
        font-size: 1.3rem;
    }
    .inOutLogDiv:hover,
    .mobiFloatLeft:hover,
    .connectBtn:hover,
    .openAccountBtn:hover,
    .backSd:hover {
        cursor: pointer;
    }
    .hoverdMe:hover {
        cursor: pointer;
    }
    .WebPage {
        direction: rtl;
        width: 90%;
        margin: 0 auto;
        max-width: 1300px;
    }
    .opDot {
        line-height: 24px;
    }
    .restDetailsBlk {
        top: unset;
        padding: unset;
        border-top-right-radius: 0%;
        border-top-left-radius: 0%;
        border-bottom: none;
    }
    .restPHeads {
        text-align: right;
    }
    .restPHeads .anakMeod {
        padding-top: 20px;
        font-size: 300%;
    }
    .onPhoneP {
        font-weight: bold;
        font-size: 1.3rem;
        float: left;
        padding-top: 40px;
    }
    .restHeader {
        font-size: 170%;
        color: black;
        margin: unset;
        display: table;
        float: right;
    }
    .restDetailsBlk {
        width: 60%;
        margin: 0 auto;
    }
    .RestMnu {
        display: block;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    .rstMnuWrap {
        width: 60%;
        /* margin: 0 auto; */
        float: right;
        margin-right: 5%;
    }
    .PaddMeFix {
        margin-right: 20%;
    }
    .tabsHeadPlace {
        z-index: unset;
        overflow: hidden;
    }
    .tabsHeadPlace.fixPos {
        width: auto;
    }
    .tabClass {
        font-weight: bold !important;
    }
    .selcedMnuIt {
        font-weight: bold !important;
    }
    .selcedMnuIt::before {
        content: ">";
        position: absolute;
        right: 0;
    }
    .fxPosWrap {
        padding-top: 45px;
    }
    .fxPosWrap li {
        font-family: var(--MyFont);
    }
    .rstMnuLeft .fxPosWrap {
        padding-top: 60px;
    }
    .phoneLine,
    .moreDetBtn {
        text-align: right;
    }
    .fixPos .fxPosWrap {
        margin-top: 70px;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
        padding: unset !important;
        line-height: 2 !important;
        min-height: unset !important;
    }
    .rstMnuRight {
        float: right;
        width: 15%;
    }
    .rstMnuLeft {
        float: left;
        width: 15%;
    }
    .productDiv,
    .prodDetails {
        padding-right: unset;
        padding-left: 20px;
        width: 65%;
        height: 100%;
    }
    .prodDetails {
        min-height: 100px;
    }
    .deskPriceANdBtns {
        overflow: hidden;
        bottom: 20px;
        position: absolute;
        width: 63%;
    }
    .css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons {
        visibility: hidden;
    }
    .serachOnMnu {
        width: 100%;
    }
    .prodSmall {
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    .prodOrderLine {
        width: 100%;
        border: none;
        float: left;
    }
    .deskItmMount {
        overflow: hidden;
    }
    .seType {
        overflow: hidden;
    }
    .priceln {
        width: 50%;
        line-height: 1.3em;
        font-size: 1.3em;
    }
    .prodLineHeadMore {
        float: left;
    }
    .plusMinDeskTop {
        width: 100%;
        overflow: hidden;
        padding-top: 5px;
    }
    .plusMinDeskTop span {
        line-height: 1.7;
        padding-right: 20px;
    }
    .dskMountItm {
        position: relative;
        top: 0;
        width: 30%;
        float: left;
        z-index: 500;
    }
    .imInOrdBtn {
        font-size: 120%;
    }
    .deskItmMount .prodLineHead span {
        line-height: 40px;
        padding-left: 15px;
        font-weight: bold;
    }
    .ContinueToPayLine:hover {
        cursor: pointer;
    }
    .ContinueToPayLine {
        width: calc(55%);
    }
    .headOnFixC {
        text-align: center;
        background-color: #daf7f9;
        padding-top: 10px;
        padding-bottom: 10px;
        position: fixed;
        z-index: 850;
        border-bottom: 1px dotted var(--lGray);
        min-height: 40px;
        line-height: 40px;
    }
    .mobiFloatLeft {
        color: white;
        position: relative;
        left: 0;
        right: unset;
        top: 0px;
        margin: 0 auto;
        height: 30px;
        width: 30px;
        padding: 0px;
        background-color: var(--turqiText);
        border-radius: 5px;
        overflow: hidden;
        transition: right 0.5s;
    }
    .imgRestDiv {
        padding-top: 25%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 68px;
        // border-bottom: 5px solid black;
    }
    .inOutOnMenu {
        float: left;
        width: unset;
        padding-top: 5px;
    }
    .onLogged {
        padding-left: 0;
        padding-top: 7%;
    }
    .Hprest {
        display: block;
        overflow: hidden;
        width: 23%;
        float: right;
        margin: 0% 1%;
        margin-top: 12px;
        border-radius: 5px;
    }
    .fotterMnu .topSlidesWrap {
        /* height: 180px; */
        /* width: 100%; */
        margin: 0 auto;
        margin-top: 15px;
        position: relative;
        overflow: hidden;
    }
    .mobiMnuInWrap.fotterSLDS .topSlidesWrap {
        margin: 0;
        width: 100%;
    }
    .HPTopImages {
        width: 100%;
        margin-bottom: 15px;
    }
    .specialPopsImages {
        width: 100%;
        margin-bottom: 15px;
    }
    .spopImgWrap {
        text-align: center;
    }
    .spopImage {
        max-width: 50%;
        margin-bottom: 15px;
    }
    .spopimgTxt {
        text-align: center;
        position: absolute;
        top: 50%;
        width: 80%;
        transform: translate(-15%, -50%);
    }
    .mediumst {
        font-size: var(--mediumFont);
        /* padding-right: 3px; */
    }
    .Hpage .theImg .css-i4bv87-MuiSvgIcon-root {
        font-size: 0.9rem !important;
    }
    .botBlock .lSide .pgSmallTxt {
        margin-right: 0px;
    }
    .mobiMnu .HPTImgWrap {
        text-align: center;
    }
    .botBlock .lSide {
        width: 40%;
    }
    .botBlock .rSide {
        width: 50%;
    }
    .mobiMnu .HPTopImage {
        max-width: 700px;
    }
    .Fotter .fatLIne {
        width: 100%;
        max-width: 1300px;
    }
    .Fotter .mobiMnuLnkItm2 {
        line-height: 35px;
        border-bottom: none;
        position: relative;
        font-size: 120%;
        float: right;
        /* top: -10px; */
        width: unset;
        margin: 0 auto;
        background-color: white;
        padding-right: 5px;
        text-align: right;
    }
    .Fotter .mobiMnuLnkItm2 div::after {
        float: left;
        content: " | ";
        padding-right: 5px;
    }
    .Fotter .mobiMnuLnkItm2:last-child div::after {
        content: "";
    }
    .pulseME:hover {
        -webkit-animation-name: pulse;
        animation-name: pulse;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }
    .NotcatMasterBtn {
        border: 1px solid var(--lGray);
        border-radius: 10px;
        width: unset;
    }
    .catBtnMasterWrap .prodImage {
        min-height: 125px;
    }
    .NotfavBtn {
        border: 1px solid var(--lGray);
        border-radius: 10px;
        /* direction: rtl; */
    }
    .imgDiv {
        padding-top: calc(42%);
        padding-bottom: 5px;
        border-radius: 10px;
    }
    .mainFavImgDiv {
        padding-top: calc(60%);
    }
    .divHead {
        margin-top: 15px;
        margin-bottom: 10px;
        padding-right: unset;
    }
    .headORdLine {
        line-height: 100%;
        padding-top: 5px;
        font-size: 14px;
        max-width: 70%;
        width: 100%;
    }
    .BottomMast {
        width: 90%;
        top: 10px;
        margin: 0 auto;
        position: relative;
        max-width: 1300px;
        z-index: 500;
        background-color: white;
    }
    .BottomBannerImages {
        width: 100%;
        margin: 0 auto;
    }
    .fourMastBtn {
        width: 94%;
        text-align: center;
        line-height: 75px;
        font-size: 110%;
        font-weight: bold;
        white-space: nowrap;
        text-align: center;
        color: black;
        margin: 0 auto;
        border-radius: 10px;
    }
    .botBlock {
        width: 96%;
        margin: 0 auto;
        position: relative;
        background-color: white;
        overflow: hidden;
        bottom: unset;
        min-height: 65px;
        border-radius: 15px;
    }
    .Fotter .MobiMenuMainMnu {
        overflow: hidden;
        float: left;
        width: 50%;
    }
    .Fotter .botmMnuLogoWrap {
        width: 50%;
        margin: 0 auto;
        float: right;
        margin-top: 0px;
    }
    .botmMnuLogoWrap .botmMnuLogo {
        width: 50%;
    }
    .botmMnuLogoTxt {
        font-size: small;
    }
    .textSizeDiag .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        // max-width: 550px;
        margin-top: 0;
    }
    .approveMsgPopup .MuiPaper-root {
        width: 80%;
        max-width: 360px !important;
        margin: 2px;
        max-height: unset;
    }
    .OrderTypeSelectPop {
        max-width: 900px;
    }
    .OrderTblPop {
        max-width: 900px;
    }
    .landingPagepopUp {
        max-width: 900px;
    }
    .popFullComp .loginPage {
        width: 100%;
    }
    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
        max-width: 200px;
    }
    .css-ghsjzk-MuiInputBase-root-MuiInput-root:before {
        max-width: 200px;
    }
    .css-ghsjzk-MuiInputBase-root-MuiInput-root {
        margin: 0 auto;
        max-width: 200px;
    }
    .Fotter .userDet {
        overflow: hidden;
        width: 50%;
        float: left;
        min-height: 80px;
    }
    .spaceRightLong {
        padding-right: 0px;
    }
    .catTextBtn {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .catText.CattxtOn {
        height: auto !important;
        line-height: 1.3;
    }
    .prodOptionsSelect {
        background-color: rgba(0, 0, 0, 0.6);
    }
    .prodOptionsSelect .headOnFixC {
        width: 50%;
        border-radius: 10px;
    }
    .prodOptionsSelectWrap {
        width: 50%;
        margin: 0 auto;
        background-color: white;
        height: 80%;
        margin-top: 4%;
        overflow: auto;
        border-radius: 10px;
    }
    .stepper {
        display: none !important;
    }
    .TheOrderListIn {
        width: 80%;
        margin: 0 auto;
    }
    .ORDTYLine {
        width: 60%;
        margin: 30px auto;
    }
    .ORDTYLine:hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    .loginPage {
        width: 100%;
        margin: 0 auto;
    }
    .addressBox #SelectAdd {
        top: 70px;
        max-width: 600px;
    }
    .mobiMnuInWrap {
        width: 60%;
        margin: 0 auto;
    }
    .fotterMnu .mobiMnuInWrap {
        width: 100%;
    }
    .catsListItem {
        width: calc(33% - 20px) !important;
    }
    .CatsPage {
        margin: 0 auto;
        margin-top: 15px;
        width: 60%;
    }
    .SinglePageContent {
        padding-top: 30px;
        position: relative;
        width: 95%;
        text-align: right;
        margin: unset !important;
    }
    .SinglePage {
        min-height: 400px;
        width: 100%;
        margin: 80px auto 0px auto;
        /* margin-top: 150px; */
        overflow: hidden;
    }
    .ContactPage {
        min-height: 400px;
        width: 100%;
        margin: 80px auto 0px auto;
        /* margin-top: 150px; */
        overflow: hidden;
    }
    .ContactPageContent {
        padding-top: 30px;
        position: relative;
        width: 95%;
        text-align: center;
    }
    .singlPImg {
        width: 100%;
        top: 20px;
        height: auto;
        /* margin: 0 auto; */
        position: relative;
        margin: 10px auto;
        overflow: hidden;
        /* max-height: 400px; */
        /* border: 1px solid;*/
    }
    .simplePSides {
        width: 100%;
    }
    .SinglePage .simplePSides {
        float: right;
        width: 50%;
    }
    .simplePSOneBLock .SinglePageContent {
        width: 100%;
        left: 15px;
    }
    .innerChargMsg {
        width: 40%;
    }
    .pgBigTxt {
        white-space: nowrap;
    }
    .iframeBlock .MuiPaper-root,
    .iframeBlock .css-uhb5lp {
        width: 50%;
        height: 100%;
        max-width: unset;
    }
    .iframeBlockIframe {
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        margin: 10px;
        border: none;
    }
    .iframeCloseBtn {
        width: 20px;
        height: 20px;
        position: absolute;
    }
    .iframeBlockDialogContent .iframeCloseBtn {
        top: 145px;
    }
    .iframeCloseBtn svg {
        width: 140%;
        height: 140%;
        transform: rotate(0deg);
        color: var(--turqiText);
        z-index: 500;
        /* left: 5px;
    top: -10px; */
        position: relative;
    }
    .mydtPicker .rdp {
        --rdp-cell-size: 50px;
    }
    .reservationhourSelItm:hover,
    .reservationSpecSelItm:hover {
        border: 1px solid var(--mainColor);
    }
    .searchDropdown {
        width: 280px;
    }
    .OrderTableBtn {
        color: white;
        text-align: center;
        border-radius: 5px;
        background-color: var(--mainColor);
        padding-top: 10px;
        padding-bottom: 10px;
        width: 25%;
        box-shadow: -2px 2px 7px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
        transition-property: box-shadow;
        transition-duration: 0.5s;
        float: left;
        margin-top: 27px;
    }
    .OrderTableBtn:hover {
        color: white;
        box-shadow: none;
    }
    .buissRedirectingBtn {
        color: white;
        background-color: var(--mainColor);
        display: table;
        padding: 10px;
        border-radius: 10px;
    }
    .buissRedirectingBtn:hover {
        color: white;
        box-shadow: none;
    }
    .offOrdItm,
    .onOrdItm {
        border: 1px solid lightgray;
        border-radius: 15px;
        padding: 15px 7px;
        margin-bottom: 10px;
    }
    .offOrdItm .smallDIspImageWrap,
    .onOrdItm .smallDIspImageWrap {
        padding: 0px 10px 10px 10px;
        text-align: center;
    }
    .offOrdItm .smallDIspImage,
    .onOrdItm .smallDIspImage {
        max-width: 130px;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 8px;
        margin: 0 auto;
    }
    .offOrdItm .orderLINE svg,
    .onOrdItm .orderLINE svg {
        color: var(--btnBlue);
    }
    .myBtnWithIcon {
        width: 125px !important;
        margin-bottom: 10px !important;
        border-color: var(--btnBlue) !important;
        color: var(--btnBlue) !important;
    }
    .myBtnWithIcon:hover {
        background-color: var(--btnBlue) !important;
        opacity: 0.5;
        color: white !important;
    }
    .myBtnWithIcon .MuiButton-startIcon {
        margin-left: 3px;
        margin-right: -4px;
    }
    .btnWithIconTxt {
        line-height: 1.2rem;
        font-size: var(--mediumFont);
    }
    .MyOrdersWrap {
        width: 80%;
        margin: 0 auto;
    }
    .ordDetailsDiv {
        border: 1px solid lightgray;
        border-radius: 15px;
        padding: 15px 7px;
    }
    .offOrdItm .ordDetailsDiv {
        /* border: none !important;
    background-color: white; */
    }
    .offOrdItm .headsline svg {
        margin: 0px 5px 0px 15px;
        top: 4px;
        position: relative;
    }
    .offOrdItm .headsline span {
        margin: 0px 5px 0px 15px;
    }
    .TheOrderShow {
        width: 90%;
        margin: 0 auto;
    }
    .shownMe {
        background-color: var(--lGray);
    }
    .MessagesObj {
        position: fixed;
        bottom: 15px;
        color: white;
        background-color: #1f1f1f;
        padding: 20px 25px;
        left: -100%;
        min-width: 300px;
        max-width: 50%;
        min-height: 150px;
        border-radius: 0px 20px 20px 0px;
        border: 2px solid white;
        transition: left 1.5s ease;
        font: var(--MyFont);
    }
    .closeItMsg {
        position: absolute;
        border-radius: 50%;
        line-height: 40px;
        color: white;
        background-color: #1f1f1f;
        width: 30px;
        height: 30px;
        text-align: center;
        top: -10px;
        right: -10px;
        border: 2px solid white;
        cursor: pointer;
    }
    .showMsgObj {
        left: 0;
    }
    .closeItMsgRot {
        rotate: 90deg;
    }
    .MessagesObjTxt {
        text-align: center !important;
    }
    /* } */
    @media only screen and (min-width: 800px) and (max-width: 1024px) {
        .prodOptionsSelect .headOnFixC {
            width: 50%;
            border-radius: 10px;
        }
        .pgSmallTxt {
            /* min-height: 45px; */
            white-space: nowrap;
        }
        .prodOptionsSelectWrap {
            width: 50%;
            margin: 0 auto;
            background-color: white;
            height: 80%;
            margin-top: 4%;
            overflow: auto;
            border-radius: 10px;
        }
        .roundedTops {
            padding-top: 20px;
            border-start-end-radius: 20px;
            border-start-start-radius: 20px;
            top: -80px;
            position: relative;
            z-index: 90;
            background-color: white;
        }
    }
}